@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

* {
  box-sizing: border-box; }

input:focus {
  outline: none; }

input:invalid {
  box-shadow: none !important; }

@-webkit-keyframes autofill {
  to {
    color: #000;
    background: transparent; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease; }
  .slick-lightbox .slick-loading .slick-list {
    background-color: transparent; }
  .slick-lightbox .slick-prev {
    left: 15px; }
  .slick-lightbox .slick-next {
    right: 15px; }

.slick-lightbox-hide {
  opacity: 0; }
  .slick-lightbox-hide.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0); }

.slick-lightbox-hide-init {
  position: absolute;
  top: -9999px;
  opacity: 0; }
  .slick-lightbox-hide-init.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0); }

.slick-lightbox-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.slick-lightbox-slick-item {
  text-align: center;
  overflow: hidden; }
  .slick-lightbox-slick-item:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; }
  .slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 20px; }
  .slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
    display: block;
    text-align: center; }

.slick-lightbox-slick-item-inner {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  max-height: 90%; }

.slick-lightbox-slick-img {
  margin: 0 auto;
  display: block;
  max-width: 90%;
  max-height: 90%; }

.slick-lightbox-slick-caption {
  margin: 10px 0 0;
  color: white; }

.slick-lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none; }
  .slick-lightbox-close:focus {
    outline: none; }
  .slick-lightbox-close:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.85;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '×'; }

.scroll-down {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 40px;
  margin: 0 auto; }

.moto {
  position: absolute;
  left: 0%;
  bottom: 70px; }

.svg_moto {
  color: white;
  width: 39px;
  height: 30px; }

.burger {
  position: relative;
  width: 25px;
  height: 30px; }

.burger span,
.burger span:before,
.burger span:after {
  position: absolute;
  left: 10px;
  top: 22px;
  width: 24px;
  height: 2px;
  background: #4ab2e7;
  border-radius: 0.2em;
  transition: all 0.3s;
  box-sizing: border-box; }

.burger span {
  top: 14px;
  left: 0; }

/* Reset the left and create the pseudo-element */
.burger span:before,
.burger span:after {
  content: '';
  left: 0; }

/* Top bar position */
.burger span:before {
  top: -8px; }

/* Bottom bar position */
.burger span:after {
  top: 8px; }

@media (min-width: 769px) {
  .burger {
    width: 35px; }
  .burger span, .burger span::after, .burger span::before {
    width: 36px; }
  .burger span::after {
    top: 10px; }
  .burger span::before {
    top: -10px; } }

.open span {
  top: 8px;
  left: 0; }

/* Get rid of more on action (IE9 or higher) */
.open:after {
  content: ''; }

/* Get rid of the middle bar on action (IE9 or higher) */
.open span {
  height: 0;
  width: 0; }

/* Moves the top and bottom bars to the middle on action (IE9 or higher) */
.open span:before,
.open span:after {
  top: 6px; }

/* Rotates the top bar on action with full browser support (IE9 or higher) */
.open span:before {
  transform: rotate(225deg); }

/* Rotates the bottom bar on action with full browser support (IE9 or higher) */
.open span:after {
  transform: rotate(-225deg); }

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/proxima/ProximaNova-Regular.otf"), url("../fonts/proxima/ProximaNova-RegularIt.otf"); }

@font-face {
  font-family: "ProximaNova";
  font-weight: 700;
  src: url("../fonts/proxima/ProximaNova-Bold.otf"), url("../fonts/proxima/ProximaNova-Bold.otf"); }

@font-face {
  font-family: "ProximaNova";
  font-weight: 900;
  src: url("../fonts/proxima/ProximaNova-Black.otf"), url("../fonts/proxima/ProximaNova-Black.otf"); }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Regular.ttf"), url("../fonts/roboto/Roboto-Regular.ttf"); }

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: url("../fonts/roboto/Roboto-Bold.ttf"), url("../fonts/roboto/Roboto-Bold.ttf"); }

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: url("../fonts/roboto/Roboto-Black.ttf"), url("../fonts/roboto/Roboto-Black.ttf"); }

* {
  font-family: 'ProximaNova'; }

body {
  font-family: 'ProximaNova'; }

.section {
  width: 100%;
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: #0b1b2f;
  background-repeat: no-repeat;
  overflow: hidden; }

.inner {
  width: 73.5%;
  max-width: 1410px;
  margin-left: auto;
  margin-right: auto; }

.header {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.logo {
  display: flex;
  align-items: center; }
  .logo__name {
    font-weight: 900;
    line-height: 0.86;
    color: #4ab2e7; }
    .logo__name_big {
      font-size: 28px; }

.nav__list {
  display: flex; }

.nav__link {
  font-weight: bold;
  text-decoration: none; }

.title {
  font-family: 'Roboto';
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.4px;
  color: #ffffff; }
  .title_huge {
    font-size: 56px; }
  .title_little {
    font-size: 46px; }

.subtitle {
  font-family: 'Roboto';
  font-weight: bold;
  line-height: 1.33;
  color: #ffffff; }
  .subtitle_middle {
    font-size: 24px; }

.text {
  font-size: 22px;
  font-weight: 300;
  color: #ffffff; }
  .text_bold {
    font-weight: bold; }
  .text_cursive {
    font-style: italic; }
  .text_less {
    font-size: 20px; }
  .text_marked {
    color: #3d99c9; }

.signature {
  font-family: 'Roboto';
  font-weight: bold;
  line-height: 0.83;
  color: #6399c7; }
  .signature_big {
    font-size: 36px; }
  .signature_little {
    font-size: 26px; }

.button {
  display: block;
  position: relative;
  z-index: 3;
  width: 100%;
  padding: 15px 0;
  outline: none;
  border: none;
  border-radius: 12px;
  background-color: #6399c7;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
  font-family: 'Roboto';
  font-weight: bold;
  color: #fff;
  transition: all .2s ease;
  cursor: pointer; }
  .button_border:hover {
    transform: translate(-9px, -8px); }
  .button_ordinary {
    width: 340px;
    height: 60px; }
  .button:hover {
    box-shadow: 0px 2px 18px 2px rgba(61, 153, 201, 0.4); }
  .button_border-container {
    width: 340px;
    position: relative; }
    .button_border-container::before {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 1;
      width: calc(100% - 3px);
      height: calc(100% - 3px);
      border: 2px solid #3d99c9;
      border-radius: 12px;
      opacity: .99; }

.block {
  position: relative;
  z-index: 3;
  border-radius: 10px;
  background-color: #082a50;
  background-color: #082a50;
  background-image: url("../img/lines.png");
  background-size: 100% 100%; }
  .block_border::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: inherit;
    border: 3px solid #082a50; }
  .block .background {
    position: relative;
    z-index: 3;
    border-radius: 10px; }
  .block__text {
    color: #fff; }
  .block__name {
    padding-top: 35px;
    padding-left: 30px; }
  .block__name_programms {
    text-transform: none;
    font-size: 26px; }

.hidden {
  display: none; }

.svg {
  fill: currentColor; }

.main-section {
  padding-top: 50px;
  padding-bottom: 50px; }

.quote {
  position: relative;
  padding-left: 120px;
  font-family: manOldStyle;
  font-size: 24px;
  font-style: italic;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: #ffffff; }
  .quote::before {
    content: "";
    position: absolute;
    left: 0;
    width: 85px;
    height: 80px;
    background-image: url("../img/icons/quotes.svg");
    background-size: contain;
    background-repeat: no-repeat; }

.item_mark {
  position: relative; }
  .item_mark .text::first-letter {
    margin-left: 30px; }
  .item_mark ul {
    margin-top: 10px; }
  .item_mark::before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 23px;
    height: 18px;
    background-image: url("../img/icons/galka.svg");
    background-size: cover;
    background-repeat: no-repeat; }
  .item_mark__second {
    position: relative; }
  .item_mark__second::before {
    content: "–";
    position: absolute;
    left: 8px;
    top: 8px;
    width: 23px;
    height: 18px;
    color: #fff; }

@media (max-width: 1750px) {
  .inner {
    width: 92%; } }

@media (max-width: 1450px) {
  .logo__name_big {
    font-size: 25px; }
  .title_huge {
    font-size: 46px; }
  .subtitle_middle {
    font-size: 20px; }
  .text {
    font-size: 20px; }
  .signature_big {
    font-size: 32px; }
  .signature_little {
    font-size: 28px; }
  .button {
    font-size: 18px; }
    .button_border-container {
      width: 320px; } }

@media (max-width: 768px) {
  .section {
    position: relative; }
    .section::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 0; }
  .inner {
    position: relative;
    z-index: 2;
    width: calc(100% - 70px); }
  .title_huge {
    font-size: 42px; }
  .subtitle_middle {
    font-size: 18px;
    line-height: 1.5; }
  .button_border-container::before {
    display: none; }
  .text {
    font-size: 17px; }
  .section {
    height: auto; }
  .button_border:hover {
    transform: none; }
  .signature_little {
    line-height: 1.38; }
  .quote {
    font-size: 18px; }
  .quote {
    position: relative;
    z-index: 2;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto; }
    .quote::before {
      top: -30px;
      opacity: .3; } }

@media (max-width: 480px) {
  .inner {
    width: calc(100% - 40px); }
  .title {
    line-height: 1.5; }
  .title_huge {
    font-size: 23px;
    letter-spacing: normal; }
  .title_little {
    font-size: 23px; }
  .subtitle_middle {
    font-size: 16px;
    line-height: 1.5; }
  .button_border-container,
  .button_ordinary {
    width: 280px;
    height: 60px;
    font-size: 16px;
    line-height: 1.76; }
  .signature_big {
    font-size: 24px; }
  .signature_little {
    font-size: 20px; }
  .block_border::before {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    top: -10px;
    left: -10px; }
  .text_less {
    font-size: 16px; }
  .block__name {
    padding-left: 15px; } }

.welcome {
  min-height: 1095px;
  padding-top: 0;
  background-image: url("../img/hero-bg-img.png");
  background-size: cover;
  background-position: top; }
  .welcome__header {
    padding-top: 40px; }

.nav__item_welcome {
  margin-right: 40px; }
  .nav__item_welcome:last-child {
    margin-right: 0; }

.nav__link_welcome {
  font-size: 18px;
  line-height: 1.33;
  color: #ffffff; }
  .nav__link_welcome:hover {
    color: #3d99c9; }

.preview {
  display: flex;
  align-items: flex-start;
  margin-top: 180px; }
  .preview__about {
    width: 55%; }
  .preview__title {
    margin-bottom: 8px; }
  .preview__subtitle {
    margin-bottom: 30px; }
  .preview__text {
    margin-bottom: 40px;
    line-height: 1.91; }
  .preview__signature {
    margin-bottom: 50px; }

.location {
  width: 450px; }
  .location__background {
    padding: 20px 35px 30px 20px;
    text-align: center; }
  .location__block {
    margin-top: 150px; }
  .location__text {
    display: block;
    text-decoration: none;
    font-weight: bold;
    line-height: 1.62; }
    .location__text_small {
      font-size: 18px; }
    .location__text_big {
      font-size: 26px; }
    .location__text_number {
      margin-bottom: 30px; }
  .location__icon {
    position: relative;
    width: 51px;
    height: 54px;
    margin-top: 10px;
    margin-right: 25px;
    color: #fff; }
    .location__icon:hover {
      color: #37bcff; }
    .location__icon_last {
      margin-right: 0; }

.logo__item_welcome {
  display: block;
  width: 116px;
  height: 81px;
  margin-right: 14px;
  background-image: url("../img/logo.png");
  background-size: cover; }

.tools, .scroll-down, .popup {
  display: none; }

@media screen and (min-width: 1000px) and (max-height: 1095px) {
  .welcome {
    height: 1095px; } }

@media (max-width: 1450px) {
  .location {
    width: 510px;
    margin-top: 350px; }
    .location__background {
      padding: 30px 35px 35px 30px; }
  .location__text_big {
    font-size: 22px; }
  .location__text_small {
    font-size: 15px; }
  .nav__item_welcome {
    margin-right: 30px; }
  .logo__item_welcome {
    width: 100px;
    height: 68px; } }

@media (max-width: 1250px) {
  .welcome {
    min-height: auto; }
    .welcome__nav {
      display: none;
      opacity: 0;
      transition: all .4s ease; }
  .tools {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .phone {
    width: 22px;
    height: 29px; }
  .svg_phone {
    width: 100%;
    height: 100%;
    color: #4ab2e7; }
  .preview {
    position: relative;
    margin-top: 80px; }
    .preview__box {
      margin-left: auto;
      margin-right: auto; }
    .preview__about {
      width: 100%; }
    .preview__location {
      position: absolute;
      right: 20px;
      top: 550px;
      margin-left: 0;
      margin-top: 0; }
  .visible_popup .popup {
    display: block;
    position: fixed; }
  .popup {
    opacity: 0; }
    .popup .location__text {
      text-decoration: none; }
      .popup .location__text_address {
        display: inline-block; }
      .popup .location__text_number {
        display: block; }
    .popup .location__link_vk {
      margin-right: 20px; }
    .popup .location__icon:last-child {
      margin-right: 0; }
  .open_menu .popup {
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: #0b1b2f;
    background-image: url("../img/lines.png");
    opacity: 1;
    transition: opacity .3s ease; }
    .open_menu .popup .preview__location {
      display: none; }
  .open_menu .nav__list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll; }
  .open_menu .nav__item {
    margin-right: 0;
    margin-bottom: 50px; }
    .open_menu .nav__item:last-child {
      margin-bottom: 0; }
  .open_menu .welcome__inner {
    position: static; }
  .open_menu .nav__link {
    display: block;
    text-align: center; }
  .open_menu .burger {
    position: fixed;
    right: 20px;
    z-index: 9; }
    .open_menu .burger span::after,
    .open_menu .burger span::before {
      background-color: white; }
  .open_connect .popup {
    display: block;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: #0b1b2f;
    opacity: 1;
    transition: opacity .3s ease; }
    .open_connect .popup .nav__list {
      display: none; }
    .open_connect .popup .preview__location {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 6;
      width: 100%;
      height: 100%;
      background-color: #0b1b2f; }
      .open_connect .popup .preview__location::before {
        display: none; }
  .open_connect .welcome__inner {
    position: static; }
  .open_connect .location__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 310px;
    height: 361px;
    margin: auto;
    border-radius: 0;
    background-color: transparent; }
  .open_connect .location__text_number {
    margin-top: 30px;
    margin-bottom: 30px; }
  .open_connect .location__text {
    text-align: center; }
    .open_connect .location__text_small {
      font-size: 18px; }
    .open_connect .location__text_number {
      border-bottom: 1px solid white; }
  .open_connect .location__icons-wrapper {
    display: flex;
    justify-content: center; }
  .open_connect .burger {
    position: fixed;
    right: 20px;
    z-index: 9; }
    .open_connect .burger span::after,
    .open_connect .burger span::before {
      background-color: white; }
  .open_connect .container {
    width: 100%; }
  .fixed .container {
    height: calc(100vh - 60px);
    width: 100%;
    left: 0;
    position: absolute;
    overflow: hidden; }
  .hide_popup .popup {
    opacity: 0;
    transition: opacity .3s ease; } }

@media (max-width: 992px) {
  .preview__location {
    right: 50px;
    margin-top: 0; } }

@media (max-width: 768px) {
  .welcome::after {
    top: 50px;
    height: calc(100% - 50px); }
  .welcome__inner {
    position: relative;
    z-index: 3; }
  .preview {
    margin-top: 130px; }
  .header {
    position: fixed;
    top: 0;
    left: 35px;
    z-index: 10;
    width: 100%;
    height: 50px;
    padding-top: 0;
    padding-left: 35px;
    padding-right: 35px;
    margin-left: -35px;
    margin-right: -35px;
    background-color: #0b1b2f; }
  .preview {
    flex-direction: column; }
    .preview__location {
      display: none; }
    .preview__text {
      line-height: 1.56; }
  .logo__item_welcome {
    width: 40px;
    height: 28px;
    background-image: url("../img/logo-mob.png"); }
  .logo__name_big {
    font-size: 18px;
    font-weight: 900;
    line-height: 1.33;
    color: #ffffff; }
  .tools {
    width: 60px; }
  .phone {
    width: 16px;
    height: 22px; }
  .burger span,
  .burger span::before,
  .burger span::after {
    background-color: white; }
  .svg_phone {
    color: white; }
  .transport_title {
    display: block; }
  .scroll-down {
    display: none; }
  .open_menu .header,
  .open_connect .header {
    width: auto;
    position: static; }
  .open_menu .preview,
  .open_connect .preview {
    margin-top: 80px; } }

@media screen and (min-width: 481px) and (max-width: 1250px) {
  .open_menu .burger,
  .open_connect .burger {
    right: 35px; } }

@media (max-width: 480px) {
  .welcome {
    min-height: auto; }
  .header {
    left: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px; }
  .preview {
    margin-top: 85px; }
    .preview__title {
      margin-bottom: 18px; }
    .preview__subtitle {
      margin-bottom: 20px; }
    .preview__signature {
      margin-bottom: 21px; }
  .transport_text {
    display: block; }
  .open_menu .preview {
    margin-top: 35px; } }

@media only screen and (max-width: 900px) and (max-height: 450px) and (orientation: landscape) {
  .open_menu .popup .nav__list {
    padding-top: 190px;
    padding-bottom: 50px; } }

.learning {
  background-image: url("../img/urich4.png");
  background-position: right; }
  .learning__inner {
    position: relative;
    padding-left: 240px; }
  .learning__text {
    width: 560px;
    line-height: 1.82; }
    .learning__text_wrong {
      margin-bottom: 40px; }
    .learning__text_cities {
      margin-bottom: 210px; }
    .learning__text_bold {
      font-weight: bold;
      font-size: 26px; }
    .learning__text_result {
      width: 680px;
      margin-left: 240px;
      margin-bottom: 35px; }
    .learning__text_important {
      width: 540px; }
  .learning__title {
    margin-bottom: 30px; }
  .learning__list {
    margin-bottom: 50px;
    color: #fff; }
  .learning__block {
    position: absolute;
    top: 430px;
    right: 0; }
  .learning__background {
    padding: 40px 30px; }
  .learning__button {
    margin-left: 240px; }

@media (max-width: 1750px) {
  .learning__inner {
    padding-left: 100px; } }

@media (max-width: 1450px) {
  .learning__text_important {
    width: 510px; } }

@media (max-width: 1250px) {
  .learning {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 10px; }
    .learning__inner {
      padding-left: 0px; } }

@media (max-width: 992px) {
  .learning__text {
    width: 95%; }
    .learning__text_result {
      margin-left: 0; }
    .learning__text_important {
      width: 100%; }
    .learning__text_cities {
      margin-bottom: 70px; }
  .learning__block {
    position: relative;
    top: 0;
    width: 550px;
    margin-bottom: 40px; }
  .learning__button {
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 768px) {
  .learning {
    background-position-x: -750px; }
    .learning__text {
      width: 100%; }
    .learning__block {
      width: 100%; } }

@media (max-width: 480px) {
  .learning {
    background-position-x: -950px; }
    .learning__background {
      padding: 30px 20px; } }

.coach {
  background-image: url("../img/moto2.png");
  position: relative;
  overflow: visible; }
  .coach__title {
    margin-bottom: 22px; }
  .coach__signature {
    margin-bottom: 35px; }
  .coach__list {
    width: 680px;
    margin-bottom: 70px; }
  .coach__about {
    position: relative;
    margin-top: 105px; }
    .coach__about .mobile-block {
      display: none; }
  .coach__advantage {
    line-height: 1.66; }
  .coach__inner {
    display: flex;
    padding-bottom: 100px; }
  .coach__results {
    width: 540px;
    margin-bottom: 50px;
    line-height: 1.82; }
  .coach__button {
    margin-left: 10px; }
  .coach__media .block_border::before,
  .coach .mobile-block_img::before {
    width: 100%;
    height: 100%;
    top: 15px;
    left: 15px;
    border: 3px solid #1e7ba8; }
  .coach__img {
    position: relative;
    z-index: 3;
    width: 664px;
    height: 416px; }
  .coach__block {
    position: absolute;
    right: -450px;
    bottom: 0; }
  .coach__text_swim {
    width: 520px;
    padding: 30px;
    line-height: 1.5; }
  .coach__quote {
    width: 920px;
    margin-bottom: 82px;
    margin-left: auto;
    margin-right: auto; }

.cone {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat; }
  .cone_big {
    width: 215px;
    height: 304px;
    right: 90px;
    bottom: 0;
    z-index: 5;
    background-image: url("../img/cone-vis.png"); }
  .cone_middle {
    width: 165px;
    height: 100px;
    bottom: 0;
    right: 140px;
    background-image: url("../img/cone_mid.png");
    background-size: contain;
    z-index: 5; }
  .cone_small {
    width: 66px;
    height: 95px;
    right: 150px;
    bottom: 300px;
    background-image: url("../img/cone_sm.png");
    background-size: contain; }

.transport_advantage {
  display: block; }

@media (max-width: 1450px) {
  .coach__img {
    width: 100%;
    height: auto; } }

@media (max-width: 1250px) {
  .coach__inner {
    flex-direction: column-reverse; }
  .coach__img {
    width: 400px; }
  .coach__media {
    display: none; }
  .coach__signature {
    margin-bottom: 50px; }
  .coach__list {
    margin-bottom: 50px; }
  .coach__about .mobile-block {
    display: block;
    width: 510px;
    position: relative;
    right: 0; }
    .coach__about .mobile-block-border_coach {
      left: calc(80% - 510px); }
    .coach__about .mobile-block_img {
      width: 550px;
      height: 360px; }
  .coach__about .block_desktop {
    display: none; }
  .coach__block {
    margin-bottom: 30px; }
  .coach__img_mobile {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%; }
  .coach__results {
    margin-bottom: 30px; }
  .cone {
    display: none; } }

@media (max-width: 1250px) {
  .coach__quote {
    width: 90%; } }

@media (max-width: 768px) {
  .coach {
    background-position-y: 350px; }
    .coach__quote {
      width: calc(100% - 70px);
      top: 20px; }
    .coach__about {
      margin-top: 0px; }
      .coach__about .mobile-block {
        width: 100%; }
        .coach__about .mobile-block-border_coach {
          left: 0; }
      .coach__about .mobile-block_img {
        width: auto;
        height: auto; }
      .coach__about .block_desktop {
        display: none; }
    .coach__text_swim {
      width: 100%; }
    .coach__list {
      width: 100%; }
    .coach__results {
      width: 100%; }
    .coach__button {
      margin-left: auto;
      margin-right: auto; }
  .transport_advantage {
    display: inline; }
  .list__title_coach {
    line-height: 1.56; } }

@media (max-width: 480px) {
  .coach__text_swim {
    padding: 25px; }
  .coach .mobile-block_img::before {
    top: 5px;
    left: 5px; }
  .coach__inner {
    padding-bottom: 0; } }

.programms {
  position: relative;
  padding-bottom: 200px;
  background-image: url("../img/yourich2.png");
  background-position-x: right;
  background-position-y: center; }
  .programms__quote {
    width: 870px; }

.transport_programms {
  display: block; }

.block_express {
  margin-top: 68px; }

.block__name {
  margin-bottom: 30px; }

.block_individuals {
  width: 50%;
  margin-left: 20%; }

.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px; }
  .flex__item {
    max-width: calc(50% - 42px); }

.text_express {
  width: 90%;
  padding-left: 30px;
  line-height: 1.5;
  margin-bottom: 25px; }

.text_price {
  margin-bottom: 0; }

.programms__item {
  margin-bottom: 15px;
  padding-left: 30px; }
  .programms__item .text {
    line-height: 1.56; }
    .programms__item .text::first-letter {
      margin-left: 0; }

.programms__title {
  margin-bottom: 60px; }

.programms__list {
  width: 100%;
  padding: 30px;
  padding-top: 0; }

.programms__quote {
  margin-left: 60px; }

.programms__background--gymkhana {
  padding-bottom: 0.05px; }

.bonus {
  width: 650px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: 240px;
  margin-bottom: 115px; }
  .bonus__item {
    line-height: 1.5; }
  .bonus__name {
    padding-left: 0; }

.helmet {
  position: absolute;
  bottom: 170px;
  width: 350px;
  height: 650px;
  background-image: url("../img/parallax-helm.png");
  background-position: right; }

@media (max-width: 1250px) {
  .programms__quote {
    width: 80%;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 992px) {
  .programms__flex {
    flex-direction: column; }
  .flex__item {
    max-width: 650px;
    width: auto; }
  .block_express {
    margin-left: auto; }
  .block_individuals {
    width: auto;
    max-width: 644px;
    margin-left: 0; }
  .bonus {
    width: auto;
    margin-right: 0;
    max-width: 100%; } }

@media (max-width: 768px) {
  .programms {
    padding-bottom: 50px; }
  .programms__quote {
    width: 100%; }
  .helmet {
    width: 116px;
    height: 277px;
    z-index: 1;
    background-size: cover; } }

@media (max-width: 480px) {
  .programms__list {
    padding-left: 15px;
    padding-right: 10px; } }

.trainings {
  min-height: 800px;
  background-image: url("../img/video.png");
  background-size: cover;
  background-attachment: fixed; }
  .trainings__attributes {
    display: flex;
    align-items: center;
    margin-bottom: 70px; }
  .trainings__logo {
    width: 265px;
    height: 128px;
    margin-right: 60px;
    background-image: url("../img/logo-moto.png"); }
  .trainings__name_big {
    font-size: 46px;
    font-weight: bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: white;
    margin-bottom: 5px; }
  .trainings__name_small {
    font-size: 26px;
    line-height: 1.23;
    font-weight: bold;
    color: #ff6207; }
  .trainings__flex {
    justify-content: space-between;
    align-items: flex-start; }
  .trainings__film-wrapper {
    width: calc(33.3% - 15px); }
  .trainings__film {
    position: relative;
    width: 100%;
    height: auto;
    transition: all .1s ease;
    background-size: cover; }
    .trainings__film::after {
      content: url("../img/youtube.svg");
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 15%;
      height: 27%;
      margin: auto; }
    .trainings__film_first {
      background-image: url("../img/prev1.jpg"); }
    .trainings__film_sec {
      background-image: url("../img/prev2.jpg"); }
    .trainings__film_third {
      background-image: url("../img/prev3.jpg"); }
  .trainings__iframe {
    display: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: height .5s ease; }
  .trainings__description {
    margin-top: 5px;
    font-family: 'Roboto';
    font-size: 22px;
    line-height: 1.82;
    color: #ffffff; }

.iframe_active::after {
  display: none; }

.iframe_active .trainings__iframe {
  display: block;
  opacity: 1; }

.social {
  width: 280px;
  margin-left: auto;
  margin-right: auto; }
  .social__title {
    color: white;
    text-align: center;
    margin-bottom: 15px; }
  .social__icon {
    width: 57px;
    height: 60px;
    color: #ff6207; }
    .social__icon:hover {
      color: white; }
  .social__link {
    margin-right: 25px; }
    .social__link:last-child {
      margin-right: 0; }
  .social__icons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; }

@media (max-width: 1250px) {
  .trainings {
    background-attachment: scroll; }
  .trainings__description {
    font-size: 18px; } }

@media (max-width: 992px) {
  .social__title {
    font-size: 19px; }
  .social__icon {
    width: 57px;
    height: 59px; }
  .trainings {
    height: auto; }
    .trainings__flex {
      display: block;
      margin-left: -15px;
      margin-right: -15px; }
    .trainings__logo {
      display: none; }
    .trainings__film-wrapper {
      position: relative; } }

@media (max-width: 768px) {
  .trainings {
    min-height: auto;
    height: auto;
    background-image: none; }
    .trainings__name_big {
      font-size: 36px; }
    .trainings__name_small {
      font-size: 20px; }
    .trainings__description {
      font-size: 13px; } }

@media (max-width: 480px) {
  .trainings__flex {
    margin-right: -20px;
    margin-left: -5px;
    margin-bottom: 30px; }
  .trainings__name_big {
    font-size: 23px; }
  .trainings__name_small {
    font-size: 16px; }
  .trainings__attributes {
    margin-bottom: 20px; }
  .social__link_trainings {
    margin-right: 45px; } }

.reviews {
  padding-bottom: 150px; }

.review__wrapper {
  height: 250px;
  overflow-y: hidden; }

.reviews__item {
  width: 100%; }

.slick-lightbox-slick-item,
.slick-slide {
  outline: none;
  border: none; }
  .slick-lightbox-slick-item:focus, .slick-lightbox-slick-item:active,
  .slick-slide:focus,
  .slick-slide:active {
    outline: none;
    border: none; }

.reviews__flx {
  width: calc(100% - 100px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  opacity: 0;
  transition: opacity .5s ease;
  transition-delay: .1s; }
  .reviews__flx .slick-slide {
    overflow: hidden; }

.visible_slider {
  opacity: 1; }

.reviews__load {
  text-align: center;
  font-style: italic;
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.6px;
  color: #ffffff; }

.slick-slide {
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer; }

.slick-arrow {
  position: absolute;
  top: calc(50% - 10px);
  width: 30px;
  height: 30px;
  background-image: url("../img/icons/arrow.svg");
  cursor: pointer; }

.slick-prev {
  width: 30px;
  height: 30px;
  background-image: url("../img/icons/arrow.svg"); }

.prev {
  transform: rotate(-180deg);
  left: -50px; }

.next {
  right: -50px; }

.slick-lightbox .slick-arrow {
  font-size: 0;
  color: transparent;
  border: none;
  background-color: transparent;
  cursor: pointer; }
  .slick-lightbox .slick-arrow:hover, .slick-lightbox .slick-arrow:active, .slick-lightbox .slick-arrow:focus {
    outline: none; }

.slick-lightbox .slick-prev {
  transform: rotate(-180deg); }

.slick-lightbox-close {
  background-image: url("../img/icons/close.svg"); }
  .slick-lightbox-close::before {
    display: none; }

@media (max-width: 480px) {
  .reviews__load {
    font-size: 17px; }
  .slick-slide {
    margin-left: 5px;
    margin-right: 5px; }
  .reviews__flx {
    width: auto;
    margin-left: -5px;
    margin-right: -20px; }
  .slick-arrow {
    display: none; }
  .slick-lightbox .slick-arrow {
    display: block; }
  .reviews {
    padding-bottom: 70px; } }

.footer {
  position: relative;
  background-color: #162035;
  border-top: 1px solid transparent; }

.footer__inner {
  width: 1145px;
  max-width: 1145px; }

.fieldset {
  width: 360px;
  max-width: 100%;
  justify-content: space-between;
  margin-bottom: 40px; }

.footer__flex {
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 0; }

.input_text {
  display: block;
  width: 360px;
  height: 45px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: solid 1px var(--dark-blue-grey-two);
  box-shadow: 0px 5px 4.9px 0.2px rgba(29, 47, 80, 0.15);
  text-indent: 20px;
  font-size: 16px; }

.input_check {
  display: none; }
  .input_check:checked ~ .custom-check {
    position: relative; }
    .input_check:checked ~ .custom-check .custom-check__inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 6px;
      height: 6px;
      background-color: #3d99c9;
      border-radius: 50%;
      transition: all .4s ease; }

.info__text_big {
  display: block;
  text-decoration: none;
  color: white; }

.custom-check {
  border-radius: 2px;
  cursor: pointer; }

.footer__button {
  width: 360px;
  text-transform: uppercase; }

.agreement {
  color: inherit; }

.footer__text {
  display: block;
  max-width: calc(100% - 26px);
  font-size: 14px;
  letter-spacing: .3px;
  line-height: 1.3; }

.info__text {
  margin-bottom: 25px;
  text-align: center;
  font-size: 18px; }
  .info__text_big {
    font-size: 22px; }

.partners {
  margin-top: 40px; }
  .partners__title {
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    line-height: 22px;
    margin-bottom: 22px;
    color: #ffffff; }
  .partners__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .partners__link {
    margin-right: 42px; }

.social__icon_footer {
  width: 57px;
  height: 68px;
  color: white; }
  .social__icon_footer:hover {
    color: #37bcff; }

.custom-check {
  width: 16px;
  height: 16px;
  border: 1px solid #3d99c9;
  background-color: white; }

.callback {
  padding-bottom: 150px; }

.footer__title {
  margin-bottom: 35px;
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 1.63;
  color: #ffffff; }

.error_input {
  border: 1px solid red; }

.error_checkbox .custom-check {
  border: 1px solid red; }

.error_checkbox .footer__text {
  color: red; }

@media (max-width: 1250px) {
  .footer__inner {
    width: 92%; } }

@media (max-width: 992px) {
  .footer__flex {
    flex-direction: column-reverse;
    align-items: center; }
  .info {
    margin-bottom: 100px; }
  .callback {
    padding-bottom: 80px; } }

@media (max-width: 480px) {
  .callback {
    width: 100%; }
  .fieldset {
    width: 100%; }
  .footer__inner {
    width: calc(100% - 40px); }
  .footer__text {
    font-size: 13px; }
  .footer__button {
    width: 280px;
    margin-left: auto;
    margin-right: auto; }
  .input_text {
    width: 100%; }
  .footer__title {
    text-align: left;
    letter-spacing: normal;
    font-size: 23px; }
  .partners__title {
    text-align: left; }
  .info__text {
    margin-bottom: 5px;
    line-height: 1.35;
    text-align: left;
    font-size: 16px; }
  .info__text_big {
    font-size: 26x;
    margin-bottom: 20px; }
  .social__icons_footer {
    justify-content: space-between;
    margin-top: 20px; } }

.popup-callback {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 55;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8); }

.loading {
  display: none; }

.popup-callback__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.loading__svg {
  width: 330px;
  height: 200px; }

.loading__title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 30px;
  margin: auto;
  color: white;
  animation: loader 2s ease-in-out infinite; }

.visible_loader {
  display: block; }

.dot {
  animation: dot 2s ease-in-out infinite; }

.dot2 {
  animation-delay: .2s; }

.dot3 {
  animation-delay: .4s; }

.message {
  display: none; }
  .message__block {
    width: 540px;
    height: 180px;
    background-color: white;
    border-radius: 5px; }
  .message__button {
    width: 170px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    background-color: #6399c7;
    border-radius: 25px; }
    .message__button:hover {
      box-shadow: 0px 2px 18px 2px rgba(99, 153, 199, 0.4); }
  .message__svg {
    width: 100%;
    height: 100%; }
  .message__title {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 22px; }

.message_success_active {
  display: block; }
  .message_success_active .message_error {
    display: none; }

.message_error_active {
  display: block; }
  .message_error_active .message_success {
    display: none; }

@keyframes loader {
  from {
    opacity: 1; }
  50% {
    opacity: .3; }
  to {
    opacity: 1; } }

@keyframes dot {
  from {
    opacity: 1; }
  50% {
    opacity: 0; }
  to {
    opacity: 1; } }

@media (max-width: 768px) {
  .popup-callback__inner {
    max-width: 90%; } }

@media (max-width: 480px) {
  .message__title {
    font-size: 18px; }
  .loading__title {
    font-size: 22px; }
  .loading__svg {
    width: 250px; }
  .message__block {
    height: 175px;
    width: 90%; }
  .social__icons_footer {
    justify-content: flex-start; }
  .footer__flex {
    margin-top: 50px; } }
